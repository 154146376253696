import Menu from "../components/menu";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import CategoryList from "../components/CategoryList";
import ArticleCard from "../components/ArticleCard";

function Articles() {
    const { t, i18n } = useTranslation();
    const [articles, setArticles]= useState();
    const [updateSelected, setUpdateSelected]= useState(false);
    const [term, setTerm]= useState("");
    const [setectedCategories, setSelectedCategories]= useState({
        'categories' : [],
        'sub_categories' : []
      });
   
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.mithaqaltashrie.com.sa/api/articles');
            if (Array.isArray(response.data.articles)) {
                const visibleArticles = []
                response.data.articles.map((article)=>{
                    visibleArticles.push({article, visibility:true})
                })
               setArticles(visibleArticles)
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData();
      }, []);
      useEffect(()=>{
      },[updateSelected])
    const handleChange = (e)=>{
        setTerm(e.target.value)
        const updatedArticles = articles
        if(term===""){
            updatedArticles.forEach((article)=>{
                article.visibility = true
            })
        }else{
            //TODO
            updatedArticles.forEach((article)=>{
                if(article.article.title.toUpperCase().includes(term.toUpperCase())){
                    article.visibility=true
                }else{
                    article.visibility=false
                }
                }
            )
        }
        setArticles(updatedArticles)
        setUpdateSelected(!updateSelected)
    } 
    const getSelectedCategories = (data)=>{
        setSelectedCategories(data)
        const updatedArticles = articles
        if(setectedCategories.categories.length===0 && setectedCategories.sub_categories.length===0 ){
            updatedArticles.forEach((article)=>{
                article.visibility = true
            })
            
        

        }
        else{
        updatedArticles.forEach((article)=>{
             if(setectedCategories.categories.indexOf(article.article.category_id)===-1 && setectedCategories.sub_categories.indexOf(article.article.sub_category_id)===-1){
                article.visibility = false
            } 
            if(setectedCategories.categories.indexOf(article.article.category_id)!==-1 || setectedCategories.sub_categories.indexOf(article.article.sub_category_id)!==-1){
                article.visibility = true
            } 
        })
       
    }
    setArticles(updatedArticles)
    setUpdateSelected(!updateSelected)


    }
    return(
        <>
        <Menu/>
        
        <div className="bg-secondary w-full relative h-28 md:h-72 flex flex-col justify-center">
            <img src="/images/overlay-5.png" className='absolute h-32 md:h-80 -bottom-4 md:-bottom-8 left-0 0 z-10' alt="" />
            <div className="relative flex md:flex-row-reverse px-4 md:px-10 lg:px-20 xl:px-36">
                <p className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-extrabold text-blue-semilight">
                    {t("Stay updated to the")}  <span className="text-primary">{t("Newest")}<br/>{t("legal")}</span> {t("information")}
                </p>
            </div>
        </div>
        <div className="w-full bg-white py-16 mx-auto px-4 md:px-0 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
            <div className="lg:px-14 w-full">
                <p className="text-black text-[40px] font-bold font-['Roboto']">{t("Mithaq Al Tashrie: Article collection")}</p>
                { <form ><div className="grid lg:grid-cols-4 gap-11 mt-10">
                    <div className="lg:col-span-3">
                        <input 
                            type="text" placeholder={t("Search an article")} 
                            className="w-full px-4 py-2 bg-white rounded-full border border-gray placeholder:text-black placeholder:font-extralight" 
                            value={term}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-span-1">
                        <input 
                            className="px-4 py-2 bg-white rounded-full border border-gray font-extralight"
                            type="submit"
                            value={t("search")}
                        />      
                    </div>
                </div></form> }
            </div>
            <div className="grid grid-cols-4 mt-4 gap-5">
                <div className="col-span-1">
                    <CategoryList selectedCategories={getSelectedCategories}/>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-5 ">
                    {articles &&  articles.map((article)=>{

                    
                        if(article.visibility===true)
                       return(<ArticleCard article={article.article} key={article.article.id} />)
                        } )
                    } 
                </div>

            </div>     
        </div>
            <Footer/>
        </>
    );
}
export default Articles

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from "react-i18next";
function Customers(){ 
    const settings = {
        autoplay : true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 900,
        slidesToShow: 3,
        /* slidesToScroll: 7 */
      };
      const {t, i18n} = useTranslation();


    
    return(
        
        <div className='px-4 py-16 lg:p-16'>
        <div>
          <p className="text-3xl text-secondary font-semibold leading-relaxed">{t("customers-title")}</p>
          <p className="text-gray">{t("customers-subtitle")}</p>
        </div>
        <div>
            <Slider {...settings} className='z-5 my-12'>
            <div  >
                           <img  src='images/customers/customer1.png' alt="logo1"  className='w-40 h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer2.png' alt="logo1"  className='w-48 h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer3.png' alt="logo1" className='h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer4.png' alt="logo1"  className='h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer5.png' alt="logo1"  className='h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer6.png' alt="logo1" className='h-36 mx-6'/>
                                
                        </div>
                        <div className="item" >
                           <img  src='images/customers/customer7.png' alt="logo1" className='w-40 h-28 mx-6'/>
                                
                        </div>
                      
                    </Slider>
        </div>
        </div>

    )
}

export default Customers;
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
function CategoryList({selectedCategories}) {
  const [categories, setCategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState({
    'categories' : [],
    'sub_categories' : []
  });
  const {t,i18n} = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://admin.mithaqaltashrie.com.sa/api/categories'); 
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  const handleCheckboxChange = (categoryId, isChecked, type) => {
    const updatedCheckedCategories = checkedCategories
    if (type==='categories'){
      if(updatedCheckedCategories.categories.includes(categoryId) ){
        updatedCheckedCategories.categories.splice(updatedCheckedCategories.categories.indexOf(categoryId,1))
      }else{
        updatedCheckedCategories.categories = [...updatedCheckedCategories.categories, categoryId]
      }
    }
    if (type==='sub_categories'){
      if(updatedCheckedCategories.sub_categories.includes(categoryId) ){
        updatedCheckedCategories.sub_categories.splice(updatedCheckedCategories.sub_categories.indexOf(categoryId,1))
      }else{
        updatedCheckedCategories.sub_categories = [...updatedCheckedCategories.sub_categories, categoryId]
      }
    }
      
    setCheckedCategories(updatedCheckedCategories);
    selectedCategories(updatedCheckedCategories);
  };

  const renderCategories = (categories) => {
    return categories.map((category) => (
      <div key={category.id} className="bg-secondary rounded-[20px] p-4 text-gold">
        <label >
          <input
            type="checkbox"
            onChange={(e) => handleCheckboxChange(category.id, e.target.checked, category.translations[0].table_name)}
          />
          {i18n.language==='ar'?"  "+category.name:"  "+category.translations[1].value}
        </label>
        {category.sub_categories && (
          <div style={{ padding: '0 20px' }}>
            {renderCategories(category.sub_categories)}
          </div>
        )}
      </div>
    ));
  };

  return <div>{renderCategories(categories)}</div>;
};


export default CategoryList